import React from 'react';
import { Col, Row } from "reactstrap";
import Form from "./Form/form";

class ContactSection extends React.Component {
    render() {

        return (
            <React.Fragment>
                <section className="section bg-light-about" id="contact">
                    <div className="container">
                        <div className="row">
                            <Col lg="12">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Calcola il tuo risparmio</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </div>

                        <Row>
                            <Col lg="12">
                                <div className="contact-box p-5">
                                    <Form></Form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default ContactSection;
