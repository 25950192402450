import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
    render() {

        return (
            <React.Fragment>
                <section className="footer-bg">
                    <div className="container">
                        <Row>
                            <Col lg="4">
                                <div className="mb-5">
                                    <p className="text-uppercase text-dark footer-title mb-4">Scarica l'APP di <br/>Mister Bolletta</p>
                                    <p className="text-muted f-14"><a href="https://apps.apple.com/it/app/mister-bolletta/id1458738572">Apple Store</a>  <a href="https://play.google.com/store/apps/details?id=it.bsmart.broker_arrow">Play Store</a></p>
                                </div>
                            </Col>

                            <Col lg="8">
                                <Row>
                                    <Col lg="4" >
                                        <div className="mb-5">
                                            <p className="text-uppercase text-dark footer-title mb-4">Contatti</p>
                                            <ul className="list-unstyled footer-sub-menu">
                                                <li className="f-14"><a href="mailto:info@misterbolletta.it" className="text-muted">info@misterbolletta.it</a></li>
                                                <li className="f-14"><a href="tel:+390282909090" className="text-muted">tel +39 02 82 90 90 90</a></li>
                                            </ul>
                                        </div>
                                    </Col>

                                    <Col lg="4">
                                        <div className="mb-5">
                                            <p className="text-uppercase text-dark footer-title mb-4">Informazioni</p>
                                            <ul className="list-unstyled footer-sub-menu">
                                                <p className="text-muted f-14">© BROKER ARROW S.R.L.</p>
                                                <p className="text-muted f-14">Via Privata Fontanesi, 4 - 20146 Milano P.IVA-C.F. 10886730968 REA MI-2564327</p>
                                                <p className="text-muted f-14">Capitale Sociale € 100.000 I.V pec: brokerarrow@legalmail.it</p>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default Footer;
