import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Input,
  Select,
  TextField,
} from "@material-ui/core";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { FormLabel } from "@mui/material";
import axios from "axios";
import React from "react";
import OtpInput from "react-otp-input";
import { v4 as uuidv4 } from "uuid";
import "./form.css";

function Form(props) {
  const [age, setAge] = React.useState("Luce");
  const [isComplete, setIsComplete] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [otp, setOtp] = React.useState("");
  const [nomeMandato, setNomeMandato] = React.useState("");
  const [files, setFiles] = React.useState(null);
  const [fileBase64, setFileBase64] = React.useState();
  const [number, setNumber] = React.useState(null);
  const [errorOtp, setErrorOtp] = React.useState(false);
  const [utenza, setUtenza] = React.useState("Privato");
  const [name, setName] = React.useState("");
  const [surname, setSurame] = React.useState("");
  const [tc, setTc] = React.useState(false);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeTC = (event) => {
    setTc(event.target.checked);
  };

  const handleChangeNumber = (event) => {
    setNumber(event.target.value);
  };
  const handleChangeNomeMandato = (event) => {
    setNomeMandato(event.target.value);
  };

  const handleChangeOtp = (value) => {
    setOtp(value);
  };

  const handleChangeUtenza = (event) => {
    setUtenza(event.target.value);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeSurname = (event) => {
    setSurame(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeFile = (event) => {
    console.log(event.target.files);
    setFiles(event.target.files);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async function saveFile(uuidMandato, token) {
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      console.log(file, "file");
      let filename =
        uuidMandato +
        "_" +
        "4_" +
        (i + 1).toString() +
        "." +
        file.type.split("/")[1];

      formData.append("file", await toBase64(file).catch((e) => Error(e)));
      formData.append("ext", file.type);
      formData.append("token", token);
      formData.append("uuid", uuidMandato);
      formData.append("category_id", "4");
      formData.append("filename", filename);
      axios
        .post(
          "https://middleware-mrbolletta-zb1-prod.herokuapp.com/engagement5",
          formData,
          {
            headers: {
              "Content-type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          console.log(`Success` + res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
    let tmpNumber = number.toString();
    if (!number.includes("+39")) {
      tmpNumber = "+39" + tmpNumber;
      setNumber(tmpNumber);
    }

    const params = new URLSearchParams();
    params.append("number", tmpNumber);
    params.append("privacy", true.toString());

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .post(
        "https://middleware-mrbolletta-zb1-prod.herokuapp.com/engagement1",
        params,
        config
      )
      .then((result) => {
        console.log(result);
      })
      .catch((err) => {});

    //saveFile();
  };

  const checkCode = () => {
    const params = new URLSearchParams();
    params.append("number", number.toString());
    params.append("sms_code", otp);

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .post(
        "https://middleware-mrbolletta-zb1-prod.herokuapp.com/engagement2",
        params,
        config
      )
      .then((result) => {
        setOpen(false);
        console.log(result.data.data);
        let user = JSON.parse(result.data.data.user);
        const token = result.data.data.token;
        user["token"] = token;
        setUser(user);
        let mandato = {};
        var uuid = uuidv4();
        mandato["step"] = -2;
        mandato["point_custom_name"] = "Richiesta landing utente " + uuid;
        mandato["kind_utility"] = age === "Luce" ? "E" : "G";
        mandato["contract_type"] = utenza === "Business" ? "B" : "H";
        mandato["name"] = user["name"];
        mandato["surname"] = user["surname"];
        mandato["uuid"] = uuid;

        const params = new URLSearchParams();
        params.append("token", user["token"].toString());
        params.append("utilities", JSON.stringify([mandato]));

        const config = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };

        axios
          .post(
            "https://middleware-mrbolletta-zb1-prod.herokuapp.com/engagement4",
            params,
            config
          )
          .then((result) => {
            let uuidMandato = result.data.data.uuid[0];
            console.log(user["token"]);
            console.log(uuidMandato);
            saveFile(uuidMandato, user["token"]);
            setIsComplete(true);
            setOpen(false);
            setUser(null);
            setOtp("");
            setNomeMandato("");
            setFiles(null);
            setNumber(null);
            setName(null);
            setSurame(null);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        setErrorOtp(true);
        setOtp("");
        setTimeout(function () {
          setErrorOtp(false);
        }, 1500);
      });
  };

  return (
    <>
      {isComplete ? (
        <div className="card">
          <div
            style={{
              borderRadius: "200px",
              height: "200px",
              width: "200px",
              background: "#ecffcf",
              margin: "0 auto",
            }}
          >
            <i className="checkmark">✓</i>
          </div>
          <h1>Ben Fatto!</h1>
          <p>
            Abbiamo ricevato i tuoi dati
            <br />
            stiamo calcolando il tuo risparmio
            <br />
            ti invieremo una mail quanto prima
          </p>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className={"displayForm"}>
            {/*<FormControl style={{width: "85%", display:"none"}} className={"myForm"}>
            <label style={{marginTop: 5}} htmlFor="motivazioni">Nome della tua fornitura</label>
            <TextField
            id="filled-name"
            label=""
            placeholder={"(ex. Casa, Ufficio)"}
            value={nomeMandato}
            required
            onChange={handleChangeNomeMandato}
            variant="outlined"
            />
            </FormControl>*/}
          </div>
          <div className={"displayForm"}>
            <FormControl className={"myForm"}>
              <FormLabel style={{ marginTop: 5 }} htmlFor="name">
                Nome
              </FormLabel>
              <TextField
                id="name"
                value={name}
                placeholder={"(ex.Mario)"}
                required
                onChange={handleChangeName}
                variant="outlined"
              />
            </FormControl>
            <FormControl className={"myForm"}>
              <FormLabel style={{ marginTop: 5 }} htmlFor="surname">
                Cognome
              </FormLabel>
              <TextField
                id="surname"
                value={surname}
                placeholder={"(ex.Rossi)"}
                required
                onChange={handleChangeSurname}
                variant="outlined"
              />
            </FormControl>
          </div>
          <div className={"displayForm"}>
            <FormControl style={{ width: "85%" }} className={"myForm"}>
              <FormLabel style={{ marginTop: 5 }} htmlFor="cell">
                Numero di telefono
              </FormLabel>
              <TextField
                id="cell"
                type={"tel"}
                value={number}
                placeholder={"Il tuo numero di cellulare"}
                required
                onChange={handleChangeNumber}
                variant="outlined"
              />
            </FormControl>
          </div>
          <div className={"displayForm"}>
            <FormControl className={"myForm"}>
              <FormLabel style={{ marginTop: 5 }} htmlFor="type-bolletta">
                Tipo di bolletta
              </FormLabel>
              <Select
                native
                id="type-bolletta"
                variant="outlined"
                required
                value={age}
                onChange={handleChange}
              >
                <option value={"Luce"}>Luce</option>
                <option value={"Gas"}>Gas</option>
              </Select>
            </FormControl>
            <FormControl className={"myForm"}>
              <FormLabel style={{ marginTop: 5 }} htmlFor="type-utenza">
                Tipo di utenza
              </FormLabel>
              <Select
                native
                id="type-utenza"
                variant="outlined"
                required
                value={utenza}
                onChange={handleChangeUtenza}
              >
                <option value={"Privato"}>Privato</option>
                <option value={"Business"}>Business</option>
              </Select>
            </FormControl>
          </div>
          <div className={"displayForm"}>
            <FormControl style={{ width: "85%" }} className={"myForm"}>
              <FormLabel style={{ marginTop: 5 }} htmlFor="upload-bolletta">
                Carica la tua ultima bolletta
              </FormLabel>
              <Button
                className={"myButtonUpload"}
                variant="contained"
                component="label"
              >
                {files ? (
                  files.length + " file selezionati"
                ) : (
                  <>
                    <ReceiptIcon style={{ color: "grey" }}></ReceiptIcon>{" "}
                    Bolletta
                  </>
                )}
                <Input
                  type="file"
                  multiple
                  onChange={handleChangeFile}
                  accept="image/png, image/jpeg, application/pdf"
                  hidden
                />
              </Button>
            </FormControl>
          </div>
          <div className={"displayForm"}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChangeTC}
                  name="checkTC"
                  checked={tc}
                />
              }
              label={
                <span>
                  Accetto i{" "}
                  <a
                    href="https://app.misterbolletta.it/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    termini e condizioni
                  </a>
                </span>
              }
              labelPlacement="end"
            />
          </div>
          <div className={"displayForm"}>
            <button
              className={"btn btn-custom"}
              disabled={
                !files ||
                files?.length === 0 ||
                !tc ||
                name === "" ||
                surname === ""
              }
              style={{ padding: 15 }}
              type="submit"
            >
              Calcola ora il tuo risparmio
            </button>
          </div>
        </form>
      )}
      <Dialog
        style={{ padding: 0 }}
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle>Inserisci il Codice che hai ricevuto via SMS</DialogTitle>
        {errorOtp && (
          <>
            <div className={"myErrorInfo"}>
              <span>Attenzione codice inserito errato</span>
            </div>
          </>
        )}
        <DialogContent>
          <OtpInput
            containerStyle={{
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
            inputStyle={{
              width: "2em",
              height: "2.6em",
              marginRight: "10px",
            }}
            value={otp}
            onChange={handleChangeOtp}
            numInputs={6}
          />
          <Button
            onClick={checkCode}
            disabled={otp?.length < 6}
            style={{ padding: 15, float: "right", marginTop: 15 }}
            type="button"
          >
            Conferma codice
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default Form;
