import customAxios from "axios";


const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const env = urlParams.get('env')
const host= env === 'dev' ? "https://backenddev.misterbolletta.it/" : "https://backend.misterbolletta.it/"
export default customAxios.create( {
        baseURL: host,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    },
);

