import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class FeatureSection extends React.Component {
    render() {

        return (
            <React.Fragment>
                <section className="section bg-features bg-light" id="features">
                    <div className="container">
                        <Row>
                            <Col className="col-lg-12">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Come Funziona</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col lg="6">
                                <div className="features-content">
                                    <div className="features-icon">
                                        <i className="pe-7s-file text-primary"></i>
                                    </div>
                                    <h4 className="font-weight-normal text-dark mb-3 mt-4">Inserisci i tuoi dati</h4>
                                    <p className="text-muted f-14">Nella sezione che troverai in basso nella pagina dovrai inserire i tuoi dati, fare una foto alla bolletta più recente, inserire il codice di verifica inviatoti via SMS per garantire la tua Privacy.</p>
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="features-img mt-32">
                                    <img src="images/features-img/img-works1.png" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>

                <section className="section bg-features">
                    <div className="container">
                        <Row className="align-items-center">
                            <Col lg="6">
                                <div className="features-img">
                                    <img src="images/features-img/img-works2.png" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="features-content mt-32">
                                    <div className="features-icon">
                                        <i className="pe-7s-check text-primary"></i>
                                    </div>
                                    <h4 className="font-weight-normal text-dark mb-3 mt-4">Verifica il risparmio</h4>
                                    <p className="text-muted f-14">Grazie al nostro metodo, che tu sia un privato o un'azienda, il miglior fornitore verrà confrontato con la tua fornitura attuale</p>
                                    <p className="text-muted f-14">Non appena il confronto sarà terminato riceverai quanto puoi risparmiare, così da procedere affidandoci il mandato per attivare la tua nuova fornitura.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>

                <section className="section bg-features bg-light">
                    <div className="container">
                        <Row className="align-items-center">
                            <Col lg="6">
                                <div className="features-content">
                                    <div className="features-icon">
                                        <i className="pe-7s-pen text-primary"></i>
                                    </div>
                                    <h4 className="font-weight-normal text-dark mb-3 mt-4">Crea il mandato</h4>
                                    <p className="text-muted f-14">Crea il mandato per iniziare a risparmiare, Zero spese e paghi solo quanto consumi. Più rimani con Mister Bolletta e meno paghi! </p>
                                    <p className="text-muted f-14">Infatti dopo 12 mesi Miste Bolletta cerca di nuovo il miglior prezzo e se c’è un ulteriore risparmio ti avverte e cambia il fornitore. </p>
                                    <p className="text-muted f 14">Creando il mandato risparmierai tempo, energia e soldi. <br/>Inizia Subito!</p>
                                    <a href="#contact" className="btn btn-custom">Calcola adesso</a>
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="features-img mt-40">
                                    <img src="images/features-img/img-works3.png" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default FeatureSection;
