import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Box,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useLocation } from "react-router";
import lock from "../../assets/images/lock.svg";
import "../../assets/styles/button.css";
import "../../assets/styles/image.css";
import PDF from "../../assets/pdf/informativa_privacy-dic_2021_f.pdf"
import customAxios from "../../api";
function Consenso() {
  const [check, setCheck] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isModalDisplayed, setIsModalDisplayed] = useState(false);
  const [isModalErrorDisplayed, setIsModalErrorDisplayed] = useState(false);
  const search = useLocation().search;

  async function sendOtp() {
    const token = new URLSearchParams(search).get("token");
    const transaction = new URLSearchParams(search).get("transaction");
    const env= new URLSearchParams(search).get("env");

    setIsLoadingButton(true);

    if (
      token === null ||
      token.length === 0 ||
      transaction === null ||
      transaction.length === 0
    ) {
      console.log(`errore, ${token} - ${transaction}`);
    } else {
      const params = new URLSearchParams();
      // params.append("token", "7a34ba38a8cffa64d044cf524a29181c76b73024");
      // params.append("transaction", "2c7b4ea2-aa93-44a4-9442-64f3b500f7d2");

      params.append("token", token);
      params.append("transaction", transaction);
      customAxios
        .post(
          "/multiaccount/send_otp",
          params,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((result) => {
          console.log(result);
          setIsModalDisplayed(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    setIsLoadingButton(false);
  }

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ backgroundColor: "#F9F9F9", minHeight: "100vh" }}
    >
      <Dialog
        open={isModalDisplayed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Richiesta effettua con successo!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              "Inserisci il codice otp ricevuto sul cellulare e inseriscilo nella tua app."
            }
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isModalErrorDisplayed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Errore durante la richiesta"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              "Si è ferificato un errore durante la richiesta del codice OTP. Per favore, ricarica la pagina"
            }
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <img className="lock-image" alt="" src={lock}></img>
        <FormGroup className="p-4">
          <FormControlLabel
            control={
              <Checkbox
                checked={check}
                onChange={(e) => {
                  setCheck(e.target.checked);
                }}
              />
            }
            label={
              <Typography variant="body2">
                Confermo di aver divulgato i miei dati e
                autorizzato la comunicazione degli stessi a
                Mister Bolletta ai fini della gestione delle
                bollette luce e gas, autorizzando Mister
                Bolletta alla trattamento dei dati per le
                finalità previste, delegando a sottoscrivere, in
                mio nome e conto ,
                <a href={PDF} target='_blank' rel='noopener noreferrer'> l’informativa Privacy ai
                  sensi dell’art. 13 del D.Lgs. 196/2003 e art. 13
                  del regolamento (UE) 2016/679 in materia di
                  protezione dei dati personali (GDPR).</a>
              </Typography>
            }
          />
        </FormGroup>

        <LoadingButton
          loading={isLoadingButton}
          className={check ? "btn-confirm" : "btn-confirm-disable"}
          variant="contained"
          color="primary"
          disabled={!check}
          onClick={() => sendOtp()}
        >
          CONFERMA
        </LoadingButton>
      </div>
    </div>
  );
}

export default Consenso;
