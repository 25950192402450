import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class AboutSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            AboutList: [{
                "icon": "mdi mdi-lightbulb",
                "title": "Esperti del settore",
                "content": "Mister Bolletta non è un fornitore, è il broker/mediatore online di Luce e Gas che valuta gratuitamente per i propri clienti le migliori offerte sul mercato, interagendo direttamente con i fornitori. Mister Bolletta ha esperti che operano da vent’anni nel mercato",
            }, {
                "icon": "mdi mdi-projector-screen",
                "title": "Mai più brutte sorprese",
                "content": "Mai più politichese o contratti fumosi. Siamo qui per aiutarti a comprendere i costi nascosti per non ricevere più brutte sorprese.",
            }, {
                "icon": "mdi mdi-nature",
                "title": "Risparmio e trasparenza",
                "content": "Agiamo  sempre in modo chiaro, ed una moltitudine  di persone risparmia una grande quantità di denaro grazie ai prezzi migliori che riusciamo ad ottenere dai fornitori di qualità che individuiamo.",
            },
            ]
        };
    }

    render() {

        return (
            <React.Fragment>
                <section className="section bg-about bg-light-about bg-light" id="about">
                    <div className="container">
                        <Row>
                            <Col lg="4">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Chi siamo</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            {this.state.AboutList.map((about, index) => {
                                return <Col lg="4" key={index}>
                                            <div className="about-box about-light text-center p-3">
                                                <div className="about-icon mb-4">
                                                    <i className={about.icon}></i>
                                                </div>
                                                <h4 className="font-weight-light">{about.title}</h4>
                                                <p className="text-muted f-14">{about.content}</p>
                                            </div>
                                        </Col>
                              })}
                         </Row>

                        <Row className="align-items-center mt-5">
                            <Col md="6">
                                <div className="about-desc">
                                    <h3 className="text-dark mb-3 font-weight-light">Il Risparmio è garantito!</h3>
                                    <p className="text-muted f-15">Mister Bolletta attiva un mandato solo nel momento in cui riusciamo a garantirti un reale risparmio rispetto all’ultima bolletta che hai pagato.
                                        Come affidarti a noi? È molto semplice:
                                        <ul>
                                            <li>Inserisci i tuoi dati</li>
                                            <li>Confermi la tua identità inserendo il codice inviatoti via SMS</li>
                                            <li>Carichi la tua bolletta sull’app</li>
                                            <li>Il nostro Team ti dirà quanto risparmierai</li>
                                        </ul>
                                       </p>
                                    <div className="about-by">
                                        <a href="#contact" className="btn btn-custom">Calcola subito</a>
                                    </div>
                                </div>
                            </Col>

                            <Col md="6">
                                <div className="about-img light-img position-relative p-4">
                                    <a href="#contact"><img src="images/Scoviamo-i-fornitori.jpg" alt="" className="img-fluid mx-auto d-block" /></a>

                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default AboutSection;
